import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Logo } from '../utils/logoProvider'
import { UserService } from '../utils/user.service'

const NavBarContainer = styled.nav`
  position: sticky;
  top: 0px;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 15px;
  box-shadow: #0000002e 0px 5px 35px -14px;
  background-color: var(--light-color);
  color: var(--primary-color);
  z-index: 50;

  &.hide-nav {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideOutUp;
  }

  &.show-nav {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideInDown;
  }

  .nav-logo-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      letter-spacing: -1px;
    }

    h3 {
      font-weight: 400;
      font-size: 16px;
      // letter-spacing: 2px;
      margin-top: 0px;
      color: var(--dark-color);
    }
  }

  .nav-logo-container {
    display: flex;
    flex-direction: row;
    padding: 13px 25px;
    padding-right: 50px;
    // background-color: var(--primary-color);
    color: var(--primary-color);
    align-items: center;

    img {
      width: 150px;
    }

    svg {
      width: 150px;
      height: auto;
      object-fit: contain;
      margin-right: 10px;

      .upper-half-logo {
        fill: var(--dark-color);
        stroke: var(--dark-color);
      }

      .lower-half-logo {
        fill: var(--light-color);
        stroke: var(--light-color);
      }
    }
  }

  .nav-link-container {
    display: flex;
    padding: 0 10px;
    align-self: flex-end;
  }

  .nav-link {
    text-decoration: none;
    padding: 20px 15px;
    color: var(--primary-color-light);
    transition: all 0.2s ease;
    cursor: pointer;
    border-bottom: 5px solid;
    border-color: #00000000;
    position: relative;

    &.active {
      color: var(--primary-color);
      border-color: unset;
    }

    &:not(.active):hover {
      color: var(--primary-color);
    }

    // &.focus .sub-menu
    &:hover .sub-menu {
      max-height: 300px;
      transition: max-height 1.3s ease-out;
    }

    .sub-menu {
      position: absolute;
      top: 64px;
      background-color: var(--light-color);
      width: 100%;
      max-height: 0;
      overflow: hidden;
      left: 0px;
      text-align: center;
      transition: max-height 0.5s ease-out;
      border-radius: 0 0 15px 15px;

        //   &::before {
        //     content: '';
        //     position: absolute;
        //     width: 10px;
        //     height: 10px;
        //     left: 10px;
        //     top: -5px;
        //     background-color: var(--light-color);
        //     transform: rotateZ(45deg);
        // }
      }
    }

    .sub-nav-link {
      padding: 10px 15px;
      border-bottom: 3px solid;
      border-radius: 0 0 15px 15px;
      transition: all 0.3s ease;

      &:hover:not(.active) {
        color: var(--dark-color);
        background: var(--dark-color-light);
        border-color: var(--dark-color);
      }

      &.active {
        color: var(--light-color);
        background: var(--primary-color-light);
        border-color: unset;
      }
    }
  }

  .nav-hamburger {
    display: none;
    position: relative;
    font-size: 25px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  @media screen and (max-width: 716px) {
    & > * {
      z-index: 50;
    }

    .nav-logo-text h3 {
      margin-top: -5px;
    }

    .nav-hamburger {
      display: block;

      #hamburger-icon {
        width: 65px;
        height: 65px;
        cursor: pointer;
        transform: translate3d(0,0,0);
        vertical-align: middle;


        path {
          fill: none;
          transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
          stroke-width: 40px;
          stroke-linecap: round;
          stroke: var(--primary-color);
          stroke-dashoffset: 0px;
        }


        path#top,
        path#bottom {
          stroke-dasharray: 240px 950px;
        }
        path#middle {
          stroke-dasharray: 240px 240px;
        }

        &.cross path#top,
        &.cross path#bottom {
          stroke-dashoffset: -650px;
          stroke-dashoffset: -650px;
        }
        &.cross path#middle {
          stroke-dashoffset: -115px;
          stroke-dasharray: 1px 220px;
        }
      }
    }

    .nav-link-container {
      position: absolute;
      flex-direction: column;
      top: 66px;
      left: 0;
      background: var(--light-color);
      width: 100%;
      z-index: 49;
      padding: unset;
      box-shadow: inset 0px 20px 35px -30px #0000002b;

      .nav-link {
        border: none;
        text-align: center;

        .sub-menu {
          background-color: var(--primary-color-light);
          z-index: 10;
          color: var(--light-color);
          box-shadow: inset 0px 0px 35px -10px #0000002b;
        }
      }

      .nav-link.active {
        color: var(--light-color);
        background-color: var(--primary-color);
        box-shadow: inset 0px 0px 35px -10px #0000002b;
      }
    }

    .menu-show {
      animation-name: fadeInDown;
      animation-duration: 0.7s;
    }

    .menu-hide {
      animation-name: fadeOutUp;
      animation-duration: 0.7s;
      animation-fill-mode: forwards;

      .nav-link {
        pointer-events: none;
      }
    }
  }
`

const Hamburger = ({ open }) => {
  return (
    <svg
      id="hamburger-icon"
      className={open ? 'cross' : ''}
      viewBox="0 0 800 600"
    >
      <path
        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
        id="top"
      ></path>
      <path d="M300,320 L540,320" id="middle"></path>
      <path
        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
        id="bottom"
        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
      ></path>
    </svg>
  )
}

export const getModifiedHash = hashToBeChanged => {
  let hash = window.location.hash
  if (hash === '' || hash === null || hash === undefined) hash = '#home'
  hash = String(hash).replace('#', '')
  if (hash === hashToBeChanged)
    hashToBeChanged =
      hashToBeChanged.charAt(0).toUpperCase() + hashToBeChanged.slice(1)
  return hashToBeChanged
  // window.location.hash = hashToBeChanged
}

const Navbar = ({ activePage, setActivePage }) => {
  const [menuOpen, setMenuOpen] = useState(false) // for smaller screen devices only
  const navigate = useNavigate()
  const [token, setToken] = useState(null)

  const menuList = useMemo(
    () => [
      { menuName: 'Home', target: 'home' },
      { menuName: 'About', target: 'about' },
      {
        menuName: 'Products',
        target: 'products'
        // subMenu: [
        //   { menuName: 'Oven', target: 'products' },
        //   { menuName: 'Mixer', target: 'products' }
        // ]
      },
      { menuName: 'Contact', target: 'contact' }
      // { menuName: 'Admin', target: null, navigate: 'admin', private: true }
    ],
    []
  )

  const [subMenuFocus, setSubMenuFocus] = useState({})

  useEffect(() => {
    const userService = UserService.getInstance()
    setToken(userService.getAccessTokenData())
  }, [setToken])

  useEffect(() => {
    let subMenuTargets = menuList.reduce((accum, currentVal) => {
      if (currentVal.subMenu) {
        accum[currentVal.target] = false
      }
      return accum
    }, {})
    setSubMenuFocus(subMenuTargets)
  }, [menuList, setSubMenuFocus])

  // const logout = useCallback(() => {
  //   const userService = UserService.getInstance()
  //   userService.removeAccessToken()
  //   navigate('/')
  //   setMenuOpen(false)
  // }, [navigate])

  const LinkClickHandler = useCallback(
    (pageName, navigateTo) => {
      if (isEmpty(navigateTo)) navigateTo = ''
      navigate(`/${navigateTo}`)
      if (!isEmpty(pageName)) {
        const hashToBeChanged = getModifiedHash(pageName)
        window.location.hash = hashToBeChanged
      }
      setMenuOpen(false)
    },
    [setMenuOpen, navigate]
  )
  const HashChangeHandler = useCallback(function () {
    let hash = window.location.hash
    const headOffset = 60
    if (hash === '' || hash === null || hash === undefined) hash = '#home'
    hash = String(hash).replace('#', '').toLowerCase()
    window.scrollTo({
      top: document.getElementById(`${hash}-container`)?.offsetTop - headOffset,
      behavior: 'smooth'
    })
    return hash
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', HashChangeHandler)
    HashChangeHandler()
    return () => window.removeEventListener('hashchange', HashChangeHandler)
  }, [HashChangeHandler, setActivePage])
  return (
    <NavBarContainer>
      <div className="nav-logo-container">
        {/* <LogoProvider /> */}
        <Logo className="nav-logo-image" />
      </div>
      <div
        className="nav-hamburger"
        onClick={() => setMenuOpen(menuOpen => !menuOpen)}
      >
        <Hamburger open={menuOpen} />
      </div>
      <div
        className={`nav-link-container ${menuOpen ? 'menu-show' : 'menu-hide'}`}
      >
        {menuList.map((item, index) => {
          return (
            ((item.private && !isEmpty(token)) || !item.private) && (
              <div
                key={`nav-link-item-${index}`}
                onClick={() => {
                  if (!item.subMenu)
                    LinkClickHandler(item.target, item.navigate)
                  else {
                    setSubMenuFocus({
                      ...subMenuFocus,
                      [item.target]: !subMenuFocus[item.target]
                    })
                  }
                }}
                className={`nav-link ${
                  activePage === item.target ||
                  (item.subMenu &&
                    item.subMenu
                      .map(({ target }) => target)
                      .includes(activePage))
                    ? 'active'
                    : ''
                } ${subMenuFocus[item.target] ? 'focus' : ''}`}
              >
                {item.menuName}
                {item.subMenu && (
                  <div className="sub-menu">
                    {item.subMenu.map((subItem, subIndex) => {
                      return (
                        <div
                          className={`sub-nav-link ${
                            activePage === subItem.target ? 'active' : ''
                          }`}
                          key={`sub-nav-item-${subIndex}`}
                          onClick={() => {
                            if (subItem?.target)
                              LinkClickHandler(subItem.target, subItem.navigate)
                          }}
                        >
                          {subItem.menuName}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          )
        })}

        {/* {token && (
          <div onClick={logout} className={`nav-link`}>
            Logout
          </div>
        )} */}
      </div>
    </NavBarContainer>
  )
}

export default Navbar
